//REACT
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { hidePopup, setMessageAndButtonText } from "../../redux/popup"
import { useDispatch, useSelector } from "react-redux";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY
import { APPOINTMENT_TYPES, DROP_DOWN_TYPES, ROLES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import CustomTextField from '../../UI/CustomTextField';
import CustomDatePicker from '../../UI/CustomDatePicker';
import YellowButton from '../../UI/YellowButton';
import CustomDropdown from '../../UI/CustomDropdown';

// Services
import AppointmentService from '../../services/AppointmentService'
import UserService from '../../services/UserService';
import CompanyService from '../../services/CompanyService';
import { showError, showSuccess } from '../../redux/snackbar';
import NavigationPanel from '../../UI/NavigationPanel';
import { formatPhone } from '../../utils/helper';
import dayjs from 'dayjs';


const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}

const AuthorizedUserReservation = () => {

    // current user info
    const user = useSelector(state => state.user)

    // initialize services
    const appointmentService = new AppointmentService()
    const userService = new UserService()
    const companyService = new CompanyService()

    const { t } = useTranslation();

    const [appointmentDate, setAppointmentDate] = useState(dayjs());
    const [appointmentTime, setAppointmentTime] = useState("");
    const [appointmentType, setAppointmentType] = useState(APPOINTMENT_TYPES.VISIT)
    const [appointmentReason, setAppointmentReason] = useState("")

    const [estimatedExitTime, setEstimatedExitTime] = useState('')
    const [arrivalTime, setArrivalTime] = useState('')

    const [location, setLocation] = useState('')
    const [notes,] = useState('')
    const [disabled, setDisabled] = useState(true);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(user?.phone);
    const [whoToMeet, setWhoToMeet] = useState("");
    const [description, setDescription] = useState("");

    const [users, setUsers] = useState([])
    const [companies, setCompanies] = useState([])
    const [fetchedUser, setFetchedUser] = useState(null)

    const [selectedCompany, setSelectedCompany] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)

    const [searchParams, setSearchParams] = useState({})
    const [searchParamsLength, setSearchParamsLength] = useState(0)

    const [companySearchParams, setCompanySearchParams] = useState({})
    const [companySearchParamsLength, setCompanySearchParamsLength] = useState(0)
    const [panels, setPanels] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (user.type === ROLES.AUTHORIZED_USER) {
            const arr = [t('adminPanel'), t('createVisit')]
            setPanels(arr)
        }
        else {
            const arr = [t('adminPanel'), t('visitProcedures'), t('createVisit')]

            setPanels(arr)
        }
    }, [])

    useEffect(() => {
        if (user) {
            fetchUsers()
            fetchUser()
            if (user?.type !== ROLES.PARTNER_COMPANY_AUTHORIZED_USER)
                fetchCompanies()
        }
    }, [])

    useEffect(() => {

        if (searchParams && searchParamsLength >= 2) {
            const params = {
                search: true,
                query: searchParams
            }
            fetchUsers(params)

        }
        else if (searchParamsLength === 0) {
            fetchUsers()
        }

        if (companySearchParams && companySearchParamsLength >= 2) {
            const params = {
                search: true,
                query: companySearchParams
            }
            fetchCompanies(params)
        }
        else if (companySearchParamsLength === 0) {
            fetchCompanies()
        }

    }, [searchParamsLength, companySearchParamsLength])

    // action function 
    const createAppointment = async (appointment) => {

        const result = await appointmentService.createAppointment(appointment)

        if (result) {
            // set snack bar's message as result's message
            clearFields()
            dispatch(showSuccess({ message: t("successMessage") }));
        }
        else
            dispatch(showError({ message: t("errorMessageTitle") }));
    }
    const clearFields = () => {

        setAppointmentTime('')
        setAppointmentReason('')
        setArrivalTime('')
        setEstimatedExitTime('')
        setPhoneNumber('')
        setFirstName('')
        setLastName('')
        setSelectedUser('')
        setDescription('')
        setSelectedCompany('')
    }
    const fetchUsers = async (params) => {
        const result = await userService.getUsers(params)

        if (result) {
            setUsers(result.data)
        }
    }

    const fetchUser = async () => {
        const result = await userService.getUserSelf()
        if (result && result.data) {
            setFetchedUser(result.data)
            setFirstName(result.data?.firstName)
            setLastName(result.data?.lastName)
            setPhoneNumber(result.data?.phone)
            setSelectedCompany({ id: result.data?.company?.id, title: result.data?.company?.name })
        }
    }

    const fetchCompanies = async (params) => {
        const result = await companyService.getCompanies(params)

        if (result) {
            setCompanies(result.data)
        }
    }

    const checkInputs = async () => {
        if (appointmentDate && appointmentTime && phoneNumber && firstName && lastName && selectedUser) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }
    // input checks, submit action
    const onSubmitAppointment = async () => {
        // TODO: Enhancement for alerts 
        if (!appointmentDate || !appointmentTime) {
            dispatch(setMessageAndButtonText({
                'title': t("errorMessageTitle"), 'text': t("errorMessage"), 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
            }))
        }
        else {
            const appointmentCreateObject = {
                firstName,
                lastName,
                phoneNumber: formatPhone(phoneNumber),
                appointmentDate,
                appointmentTime,
                arrivalTime,
                appointmentType,
                appointmentReason,
                estimatedExitTime,
                location,
                description,
                notes,
                userId: selectedUser ? selectedUser.id : null,
                companyId: selectedCompany.id ? selectedCompany.id : user.companyId,
            }

            await createAppointment(appointmentCreateObject)
        }

    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>




            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }} onChange={() => checkInputs()} >

                    {/* ********************************************************************** */}
                    {/* 1st ROW OF INPUT FIELD */}
                    {/* ********************************************************************** */}
                    <Grid container xs={12} direction="column" justifyContent="space-between" >

                        <Grid item container justifyContent="space-between">
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('firstName2')}
                                    label={t('firstName2')}
                                    value={firstName}
                                    setValue={setFirstName}
                                />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('lastName')}
                                    label={t('lastName')}
                                    value={lastName}
                                    setValue={setLastName}
                                />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomDropdown
                                    size="small"
                                    margin="normal"
                                    disabled={user?.type === ROLES.PARTNER_COMPANY_AUTHORIZED_USER}
                                    data={
                                        companies?.map(company => {
                                            return { title: company.name, id: company.id }
                                        })
                                    }
                                    label={t('company')}
                                    value={selectedCompany}
                                    setValue={setSelectedCompany}
                                    searchParams={companySearchParams}
                                    setSearchParams={setCompanySearchParams}
                                    setSearchParamsLength={setCompanySearchParamsLength}
                                    fetchFunction={fetchCompanies}
                                />
                            </Grid>

                        </Grid>

                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item xs={3.9}>
                                {
                                    user ? (
                                        <CustomDropdown
                                            size="small"
                                            margin="normal"
                                            data={
                                                users?.map(user => {
                                                    return { title: user.firstName + ' ' + user.lastName, id: user.id }
                                                })
                                            }
                                            label={t('authInputField3')}
                                            value={selectedUser}
                                            setValue={setSelectedUser}
                                            searchParams={searchParams}
                                            setSearchParams={setSearchParams}
                                            setSearchParamsLength={setSearchParamsLength}
                                            fetchFunction={fetchUsers}
                                        />
                                    ) :
                                        (
                                            <CustomTextField value={whoToMeet} placeholder={t('authInputField3')} label={t('authInputField3')} setValue={setWhoToMeet} />
                                        )
                                }
                            </Grid>
                            <Grid item xs={3.9}>
                                <Grid item xs={12}>
                                    <CustomDatePicker
                                        type={DROP_DOWN_TYPES.SELECT}
                                        placeholder={t('appointmentDate')}
                                        label={t('appointmentDate')}
                                        value={appointmentDate}
                                        setValue={setAppointmentDate}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('phoneNumberLabel')} label={t('phoneNumberLabel')} value={phoneNumber} type="tel" setValue={setPhoneNumber} />
                            </Grid>

                        </Grid>

                        {/* ********************************************************************** */}
                        {/* 3rd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('appointmentTime')}
                                    label={t('appointmentTime')}
                                    type="time"
                                    value={appointmentTime}
                                    setValue={setAppointmentTime}
                                />
                            </Grid>
                            <Grid item xs={7.95}>
                                <CustomTextField
                                    placeholder={t('appointmentReason')}
                                    value={appointmentReason}
                                    label={t('appointmentReason')}
                                    setValue={setAppointmentReason}
                                />

                            </Grid>
                            {/* {
                                user.type === ROLES.SECURITY_GUARD &&

                                <Grid item xs={3.9}>
                                    <CustomTextField
                                        placeholder={t('arrivalTime')}
                                        value={arrivalTime}
                                        type='time'
                                        label={t('arrivalTime')}
                                        setValue={setArrivalTime}
                                    />
                                </Grid>
                            } */}

                        </Grid>

                        {/* <Grid item container sx={{ mt: '2rem', justifyContent: 'center' }}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    placeholder={t('estimatedExitTime')}
                                    value={estimatedExitTime}
                                    type='time'
                                    label={t('estimatedExitTime')}
                                    setValue={setEstimatedExitTime}
                                />
                            </Grid>

                        </Grid> */}

                        {/* ********************************************************************** */}
                        {/* 4th ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem', justifyContent: 'center' }}>
                            <CustomTextField
                                placeholder={t('authInputField5')}
                                isDescriptionTF={true}
                                label={t('authInputField5')}
                                multiline
                                rows={6}
                                value={description}
                                setValue={setDescription}

                            />
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* BUTTON */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem' }}>
                            <Grid item xs={4}>
                                <YellowButton
                                    disabled={disabled}
                                    type="submit"
                                    onClick={() => onSubmitAppointment()}
                                >
                                    {t('commonCreateButton')}
                                </YellowButton>
                            </Grid>




                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid>


        </MainCard>
    )

}


export default AuthorizedUserReservation;