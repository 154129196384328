//REACT
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

//MUI
import { Grid, FormGroup } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

// CUSTOM COMPONENTS
import { AREAS_FOR_3020_FREIGHTS, DROP_DOWN_TYPES, FREIGHT_AREAS_ANG_GATES, ROLES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import { hidePopup, setMessageAndButtonText } from "../../redux/popup"
import AppointmentService from '../../services/AppointmentService'
import { APPOINTMENT_TYPES, FREIGHT_AREAS } from '../../utils/constants';
import CustomDropdown from '../../UI/CustomDropdown';
import { showSuccess } from '../../redux/snackbar';
import { modifyPlate } from '../../utils/modifyPlate';
import NavigationPanel from '../../UI/NavigationPanel';
import CustomDatePicker from '../../UI/CustomDatePicker';

// LIBRARIES
import dayjs from 'dayjs';

// SERVICES
import CompanyService from '../../services/CompanyService';
import UserService from '../../services/UserService';
import { removeSpaces } from '../../utils/helper';
import { WarningOutlined } from '@mui/icons-material';


const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    }
}

const LoadReservation = (props) => {

    const user = useSelector(state => state.user)

    const { t } = useTranslation();

    const [phoneNumber, setPhoneNumber] = useState();
    const [plate, setPlate] = useState("");
    const [trailerNumber, setTrailerNumber] = useState("");

    const [appointmentDate, setAppointmentDate] = useState(dayjs());
    const [gateNumber, setGateNumber] = useState("");
    const [availableGates, setAvailableGates] = useState([])

    const [slots, setSlots] = useState([])
    const [selectedSlot, setSelectedSlot] = useState({});
    const [availableSlots, setAvailableSlots] = useState([])

    const [selectedCompany, setSelectedCompany] = useState();
    const [companyList, setCompanyList] = useState([]);

    const [freightArea, setFreightArea] = useState("");

    const [description, setDescription] = useState("");
    const [panels, setPanels] = useState([t('adminPanel'), t('freightOperations'), t('bookANewFreightAppointment')])

    const [fetchedUser, setFetchedUser] = useState({})

    const [companySearchParams, setCompanySearchParams] = useState({})
    const [companySearchParamsLength, setCompanySearchParamsLength] = useState(0)

    // initialize services
    const appointmentService = new AppointmentService()
    const companyService = new CompanyService()
    const userService = new UserService()

    const dispatch = useDispatch();

    useEffect(() => {
        if (user.type === ROLES.PARTNER_COMPANY_AUTHORIZED_USER) {
            setPhoneNumber(user.company.phone)
            setSelectedCompany({ id: user.companyId, title: user.company.name, phone: user.company.phone })
        } else
            fetchCompanies()
    }, [user])

    useEffect(() => {
        setPhoneNumber(selectedCompany?.phone)
    }, [selectedCompany])

    useEffect(() => {
        if (freightArea) {
            setGateNumber('')
            setSelectedSlot('')
            setAvailableGates(FREIGHT_AREAS_ANG_GATES[freightArea])
        }
    }, [freightArea])

    useEffect(() => {
        if (companySearchParams && companySearchParamsLength >= 2) {
            const params = {
                search: true,
                query: companySearchParams
            }
            fetchCompanies(params)
        }
    }, [companySearchParams, companySearchParamsLength])

    useEffect(() => {
        // MAIS SEVKIYAT 
        if (gateNumber && appointmentDate && freightArea) {
            const params = {
                freightArea,
                appointmentDate: dayjs(appointmentDate).format('YYYY-MM-DD'),
                startTime: dayjs(appointmentDate).isSame(dayjs(), 'day') ? dayjs().format('HH:mm') : '',
                gateNumber,
            }
            getAvailableSlots(params)
        }
        // OTHER FREIGHT AREAS 
        else if (appointmentDate && freightArea && freightArea !== FREIGHT_AREAS.MAIS_SEVKIYAT && freightArea !== FREIGHT_AREAS[3020]) {
            const params = {
                freightArea,
                appointmentDate: dayjs(appointmentDate).format('YYYY-MM-DD'),
                startTime: dayjs(appointmentDate).isSame(dayjs(), 'day') ? dayjs().format('HH:mm') : ''
            }
            getSlots(params)
        }
    }, [gateNumber, appointmentDate, freightArea])

    // special case for 3020 (gate number is a description in this case)
    useEffect(() => {
        if (appointmentDate && freightArea && freightArea === FREIGHT_AREAS[3020] && description && AREAS_FOR_3020_FREIGHTS.map(area => area.value).includes(description)) {
            const params = {
                freightArea,
                appointmentDate: dayjs(appointmentDate).format('YYYY-MM-DD'),
                startTime: dayjs(appointmentDate).isSame(dayjs(), 'day') ? dayjs().format('HH:mm') : '',
                gateNumber: parseInt(description)
            }
            getAvailableSlots(params)
        }
    }, [description, appointmentDate, freightArea])

    const getAvailableSlots = async (params) => {

        const result = await appointmentService.getAvailableSlots(params)
        if (result) {
            setAvailableSlots(result.data)
        }
    }

    const getSlots = async (params) => {
        const result = await appointmentService.getSlots(params)
        if (result) {
            setAvailableSlots(result.data)
        }
    }

    const clearFields = async () => {
        setFreightArea('')
        setAvailableSlots([])
        setPlate('')
        setTrailerNumber('')
        setDescription('')
        setGateNumber('')
    }

    const createAppointment = async (appointment) => {

        const result = await appointmentService.createAppointment(appointment)

        if (result) {
            dispatch(showSuccess({ message: t("successMessage") }));
            clearFields()
        }
        else {
            dispatch(setMessageAndButtonText({
                title: t("errorMessageTitle"),
                text: 'Randevu Oluşturulamadı!',
                noButtonText: t("commonOkButton"),
                noButtonAction: () => { dispatch(hidePopup()) },
                isErrorMessage: true
            }))
        }
    }

    const handleSubmit = async () => {

        const appointmentCreateObject = {
            appointmentType: APPOINTMENT_TYPES.OPERATION,
            companyId: selectedCompany?.id,
            phoneNumber,
            freightArea,
            plate: removeSpaces(plate),
            trailerNumber: removeSpaces(trailerNumber),
            appointmentDate,
            appointmentTime: selectedSlot?.START,
            gateNumber: (freightArea === FREIGHT_AREAS[3020] && description) ? parseInt(description) : gateNumber,
            slotNumber: selectedSlot?.SLOT_NUMBER,
            description
        }

        if (validateAppointmentObject(appointmentCreateObject)) {
            await createAppointment(appointmentCreateObject)
        }
        else {
            dispatch(setMessageAndButtonText({
                'title': t("errorMessageTitle"), 'text': t("errorMessage"), 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
            }))
        }
    }

    const validateAppointmentObject = (appointmentCreateObject) => {
        return Object.keys(appointmentCreateObject).every(key => {
            if (appointmentCreateObject.freightArea === FREIGHT_AREAS.MAIS_SEVKIYAT && !appointmentCreateObject.gateNumber)
                return false
            else if (!appointmentCreateObject[key] && key !== 'description' && key !== 'trailerNumber' && key !== 'gateNumber')
                return false
            else return true
        })
    }

    const fetchCompanies = async (params) => {
        const result = await companyService.getCompanies(params)

        if (result) {
            setCompanyList(result.data)
        }
    }

    // for both partner company standard user and partner company authorized user, returns a custom dropdown component
    const companyFieldDependsOnTheUserType = (user) => {

        // if user is a partner company standard user or partner company authorized user, return a text field with the company name
        if (user.type === ROLES.PARTNER_COMPANY_AUTHORIZED_USER) {
            return (
                <CustomTextField
                    label={t('companyWhichBook')}
                    placeholder={t('companyWhichBook')}
                    value={selectedCompany?.title}
                    disabled={true}
                />
            )
        } else { // return a dropdown component with the company list for others (ypdm users)
            return (
                <CustomDropdown
                    label={t('companyWhichBook')}
                    placeholder={t('companyWhichBook')}
                    value={selectedCompany}
                    setValue={setSelectedCompany}
                    searchParams={companySearchParams}
                    setSearchParams={setCompanySearchParams}
                    setSearchParamsLength={setCompanySearchParamsLength}
                    fetchFunction={fetchCompanies}
                    data={companyList.map((company) => { return { id: company.id, title: company.name, phone: company.phone } })}
                />
            )
        }
    }
    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            {/* Information */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                {/* Near blue color */}
                <InfoIcon sx={{ color: '#1976d2', mr: '0.5rem' }} />
                <p style={{ color: '#1976d2', fontWeight: 'bold' }}>{t('loadReservationNote')}</p>
            </Grid>

            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }}>

                    {/* ********************************************************************** */}
                    {/*  INPUT FIELDS */}
                    {/* ********************************************************************** */}
                    <Grid container xs={12} direction="column" justifyContent="space-between" >

                        <Grid item container justifyContent="space-between">
                            <Grid item xs={3.9}>
                                {companyFieldDependsOnTheUserType(user)}

                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    label={t('phoneNumberLabel')}
                                    value={phoneNumber}
                                    setValue={setPhoneNumber}
                                    type="tel"
                                    placeholder={t('phoneNumberLabel')}
                                />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomDatePicker
                                    label={t('appointmentDate')}
                                    value={appointmentDate}
                                    defaultValue={appointmentDate}
                                    setValue={setAppointmentDate}
                                    minDate={dayjs()}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    value={modifyPlate(plate)}
                                    label={t('towTruckPlate')}
                                    setValue={setPlate}
                                    placeholder={t('towTruckPlate')}
                                />

                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    value={modifyPlate(trailerNumber)}
                                    label={t('truckPlateLabel')}
                                    setValue={setTrailerNumber}
                                    placeholder={t('truckPlateLabel')}
                                />

                            </Grid>
                            {/* Freight Area -> Operasyon alani */}
                            <Grid item xs={3.9}>
                                <CustomDropdown
                                    type={DROP_DOWN_TYPES.SELECT}
                                    label={t('freightArea')}
                                    placeholder={t('freightArea')}
                                    value={freightArea}
                                    setValue={setFreightArea}
                                    data={Object.keys(FREIGHT_AREAS).map((area, i) => { return { id: i, title: t(`${area}`), value: area } })}
                                    disabled={!appointmentDate}
                                />
                            </Grid>

                        </Grid>



                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>


                            {
                                freightArea === FREIGHT_AREAS[3020] ?
                                    <>
                                        <Grid item xs={7.95}>
                                            <CustomDropdown
                                                type={DROP_DOWN_TYPES.SELECT}
                                                label={t('Bölüm')}
                                                placeholder={t('Bölüm')}
                                                value={description}
                                                setValue={setDescription}
                                                data={AREAS_FOR_3020_FREIGHTS.map((area, i) => { return { id: i, title: area.label, value: area.value } })}
                                            />
                                        </Grid>

                                        <Grid item xs={3.9}>
                                            <CustomDropdown
                                                type={DROP_DOWN_TYPES.SELECT}
                                                label={t('appointmentTime')}
                                                placeholder={t('appointmentTime')}
                                                value={selectedSlot}
                                                setValue={setSelectedSlot}
                                                data={availableSlots.map((slot, i) => ({ id: i, title: `${slot.START} - ${slot.END}`, value: slot }))}
                                            />
                                        </Grid>
                                    </>
                                    :

                                    freightArea === FREIGHT_AREAS.MAIS_SEVKIYAT ?

                                        <>
                                            {/* Kapi (Gate) */}
                                            <Grid item xs={3.9}>
                                                <CustomDropdown
                                                    type={DROP_DOWN_TYPES.SELECT}
                                                    label={t('gateNumber')}
                                                    placeholder={t('gateNumber')}
                                                    value={gateNumber}
                                                    setValue={setGateNumber}
                                                    disabled={!freightArea || !appointmentDate}
                                                    data={availableGates.map((gate, i) => { return { id: i, title: gate, value: gate } })}
                                                />
                                            </Grid>
                                            <Grid item xs={3.9}>
                                                <CustomDropdown
                                                    type={DROP_DOWN_TYPES.SELECT}
                                                    label={t('appointmentTime')}
                                                    placeholder={t('appointmentTime')}
                                                    value={selectedSlot}
                                                    setValue={setSelectedSlot}
                                                    data={availableSlots.map((slot, i) => { return { id: i, title: `${slot?.START} - ${slot?.END}`, value: slot } })}
                                                />
                                            </Grid>
                                            <Grid item xs={3.9}>
                                                <CustomTextField
                                                    label={t('description')}
                                                    value={description}
                                                    setValue={setDescription}
                                                    placeholder={t('description')}
                                                />
                                            </Grid>
                                        </>

                                        : // OTHER FREIGHT AREAS

                                        <>
                                            <Grid item xs={3.9}>
                                                <CustomDropdown
                                                    type={DROP_DOWN_TYPES.SELECT}
                                                    label={t('appointmentTime')}
                                                    placeholder={t('appointmentTime')}
                                                    value={selectedSlot}
                                                    setValue={setSelectedSlot}
                                                    data={availableSlots.map((slot, i) => { return { id: i, title: `${slot?.START} - ${slot?.END}`, value: slot } })}
                                                />
                                            </Grid>
                                            <Grid item xs={7.95}>
                                                <CustomTextField
                                                    label={t('description')}
                                                    value={description}
                                                    setValue={setDescription}
                                                    placeholder={t('description')}
                                                />
                                            </Grid>
                                        </>
                            }
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* BUTTON ROW*/}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '1rem' }} >
                            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                                <WarningOutlined sx={{ color: '#f57c00', mr: '0.5rem' }} />
                                <p style={{ color: '#f57c00', fontWeight: 'bold' }}>{t('loadReservationWarning')}</p>
                            </Grid>

                            <Grid item xs={4} >
                                <YellowButton
                                    type="submit"
                                    onClick={() => { handleSubmit() }}
                                >
                                    {t('create')}
                                </YellowButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormGroup>
            </Grid>
        </MainCard>
    )
}
export default LoadReservation;