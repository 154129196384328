
//REACT
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//MUI
import { Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

//LIBRARY
import YellowButton from '../../UI/YellowButton';
import MainCard from '../../UI/MainCard';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES, FREIGHT_AREAS, RESPONSE_TYPES, ROLES, ROUTES } from '../../utils/constants'
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import AppointmentService from '../../services/AppointmentService';
import BlackButton from '../../UI/BlackButton';
import { useDispatch, useSelector, } from "react-redux";
import { showError, showSuccess } from '../../redux/snackbar';
import { hidePopup, setMessageAndButtonText } from '../../redux/popup';
import NavigationPanel from '../../UI/NavigationPanel';
import { displayPlate } from '../../utils/helper';



const LoadReservationApproval = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentUser = useSelector((state) => state.user);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(30);

    const [params, setParams] = useState({
        page,
        limit,
        appointmentStatus: APPOINTMENT_STATUSES.PENDING,
        appointmentType: APPOINTMENT_TYPES.OPERATION,
    });

    const [appointments, setAppointments] = useState([]);
    const appointmentService = new AppointmentService();
    const [appointmentId, setAppointmentId] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const getTheme = useThemes()
    const darkMode = useSelector(state => state.darkMode)
    const [totalCount, setTotalCount] = useState(0)

    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const dispatch = useDispatch();

    const [panels, setPanels] = useState([t('adminPanel'), t('freightOperations'), t('reservationApproval')])

    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            backgroundColor: darkMode ? 'black' : 'white',

            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '100px' : '80px',
            borderRadius: 10,
            p: 2,
        },

    }

    // service call for appointments with pagination
    const getAppointments = async (params) => {
        try {

            const response = await appointmentService.getAppointments(params);
            if (response && response.data) {
                setAppointments(response.data)
                setTotalCount(response.count)
            }
        }
        catch (e) {
            console.log(e);
        }

    }

    const getActionColumn = (object) => {
        return (
            <YellowButton
                style={{ height: '30px', width: '110px', borderRadius: 5, fontSize: 11 }}
                onClick={() => dispatch(setMessageAndButtonText({
                    'title': object.freight?.plate, 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                }))}>
                {t('commonShowDescription')}
            </YellowButton>
        );
    };

    /* approve a reservation which clicked in datagrid table */
    const approveReservation = async (object) => {
        const body = { appointmentStatus: APPOINTMENT_STATUSES.APPROVED }

        // valid appointment update object
        if (object.id && body) {
            const result = await appointmentService.updateAppointment(object.id, body)
            if (result.status === RESPONSE_TYPES.success) {
                const list = appointments.filter(item => item.id !== object.id)
                setAppointments(list);

                dispatch(showSuccess({ message: t("successMessage") }))
                if (appointments.length <= 1)
                    setIsEmpty(!isEmpty)
            }
            else
                dispatch(showError({ message: t("errorMessageTitle") }))
        }
    };

    /* reject a reservation which clicked in datagrid table */
    const declineReservation = async (object) => {

        const body = { appointmentStatus: APPOINTMENT_STATUSES.REJECTED }
        setAppointmentId(object.id)

        // valid appointment update object
        if (object.id && body) {
            const result = await appointmentService.updateAppointment(object.id, body)
            if (result.status === RESPONSE_TYPES.success) {

                const list = appointments.filter(item => item.id !== object.id)
                setAppointments(list);
                dispatch(showSuccess({ message: t("successMessageForDelete") }))
                if (appointments.length <= 1)
                    setIsEmpty(!isEmpty)
            }
            else
                dispatch(showError({ message: t("errorMessageTitle") }))
        }
    };

    /* function to allow us to do a operation for particular row, in this case approve and reject operation */
    const getActionColumnDetail = (object) => {
        // if (object?.freight?.freightArea === FREIGHT_AREAS.MAIS_SEVKIYAT) {
        // return a assign button for mais sevkiyat and navigate to edit page 
        return (
            <>
                <Grid justifyContent={"center"}>
                    <YellowButton
                        sx={{ width: '120px', height: '30px', borderRadius: 5, fontSize: 11, backgroundColor: '#2980b9', color: 'white' }}
                        type="submit"
                        onClick={() => navigate(ROUTES.LOAD_RESERVATION_EDIT, { state: { request: object, isApproval: true } })}
                    >
                        {t('assignGateAndRoute')}
                    </YellowButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("rejectFreightAppointment"),
                            'text': t("rejectFreightAppointmentText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("reject"),
                            yesButtonAction: () => {
                                declineReservation(object);
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}>
                        <CloseIcon sx={{ color: 'red' }} />
                    </IconButton>
                </Grid>
            </>
        )
        //     }
        //     return (
        //         <Grid container item justifyContent="space-around">
        //             <Grid item>
        //                 <IconButton
        //                     onClick={() => (approveReservation(object))}>
        //                     <DoneIcon sx={{ color: '#469D89' }} />
        //                 </IconButton>
        //             </Grid>
        //             <Grid item>
        //                 <IconButton
        //                     onClick={() => dispatch(setMessageAndButtonText({
        //                         'title': t("rejectFreightAppointment"),
        //                         'text': t("rejectFreightAppointmentText"),
        //                         'noButtonText': t("commonCancelButton"),
        //                         noButtonAction: () => {
        //                             dispatch(hidePopup())
        //                         },
        //                         'yesButtonText': t("reject"),
        //                         yesButtonAction: () => {
        //                             declineReservation(object);
        //                             dispatch(hidePopup())
        //                         },
        //                         "isErrorMessage": false
        //                     }))}>
        //                     <CloseIcon sx={{ color: 'red' }} />
        //                 </IconButton>
        //             </Grid>
        //         </Grid>

        //     );
    };

    {/*Delete the one row which was clicked*/ }
    const deleteRow = async (object) => {
        const body = { appointmentStatus: APPOINTMENT_STATUSES.REJECTED }


        // valid appointment update object
        if (object.id && body) {

            // cancel appointment
            const result = await appointmentService.updateAppointment(object.id, body)

            if (result.status === RESPONSE_TYPES.success) {
                setAppointments(appointments.filter(item => item.id !== object.id));
            }

            else {
                console.log(result)
                dispatch(showError({ message: t("errorMessageTitle") }))
            }
        }
    };

    //örnek column oluşturma
    const columns = [
        {
            field: 'details',
            headerName: t('details'),
            minWidth: 200,
            renderCell: (params) => getActionColumnDetail(params.row),
            filterable: false,
        },
        {
            field: 'company',
            headerName: t('company'),
            minWidth: 170,
            filterable: false,
            renderCell: (params) => (
                <Grid container>
                    <Typography sx={styles.tooltipTypography}>{params.row.company?.name}</Typography>
                </Grid>
            ),
        },
        {
            field: 'phone',
            headerName: t('phoneNumberLabel'),
            width: 220,
            filterable: false,
            renderCell: (params) => params.row.company?.phone
        },
        {
            field: 'appointmentStatus',
            headerName: t('appointmentStatus'),
            minWidth: 250,
            filterable: false,
            renderCell: (params) => t(`${params.row.appointmentStatus}`)
        },
        {
            field: 'appointmenType',
            headerName: t('appointmentType'),
            minWidth: 180,
            filterable: false,
            renderCell: (params) => t(`${params.row.appointmentType}`)
        },
        {
            field: 'appointmentDate',
            headerName: t('appointmentDate'),
            filterable: false,
            minWidth: 180,
        },
        {
            field: 'appointmentTime',
            headerName: t('appointmentTime'),
            minWidth: 180,
            filterable: false,
            renderCell: (params) => params.row.appointmentTime?.slice(0, 5)
        },
        {
            field: 'towTruckPlate',
            headerName: t('towTruckPlate'),
            minWidth: 180,
            // filterable: false,
            renderCell: (params) => displayPlate(params.row.freight?.plate)
        },
        {
            field: 'trailerNumber',
            headerName: t('truckPlateLabel'),
            minWidth: 180,
            // filterable: false,
            renderCell: (params) => displayPlate(params.row.freight?.trailerNumber)
        },
        {
            field: 'freightArea',
            headerName: t('freightArea'),
            minWidth: 180,
            filterable: false,
            renderCell: (params) => t(`${params.row.freight?.freightArea}`)
        },
        {
            field: 'gateNumber',
            headerName: t('gateNumber'),
            minWidth: 150,
            filterable: false,
            renderCell: (params) => params.row.freight?.gateNumber
        },
        {
            field: 'description',
            headerName: t('description'),
            minWidth: 200,
            filterable: false,
            renderCell: (params) => getActionColumn(params.row)
        }
    ];

    return (
        <MainCard isSingleCard={false}>

            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }}>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={appointments}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('reservationApproval')}
                        getFunction={getAppointments}
                        isAddButton={false}
                        totalCount={totalCount}
                        params={params}
                    />
                </Grid>
            </Grid>




        </MainCard>

    );
}

export default LoadReservationApproval;