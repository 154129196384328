import { ENDPOINTS } from "../utils/constants"
import axios from "../utils/interceptor"

export default class CompanyService {

    constructor() {
        this.endpoint = ENDPOINTS.COMPANIES
    }

    getCompanies = async (params) => {
        try {
            // Request
            let result = await axios.get(this.endpoint.BASE, { params })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data
        }

        catch (e) {
            return Promise.reject(e)
        }
    }

    getCompaniesPublic = async (params) => {
        try {
            // Request
            let result = await axios.get(this.endpoint.PUBLIC, { params })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data
        }

        catch (e) {
            return Promise.reject(e)
        }
    }

    createCompany = async (company) => {

        let url = this.endpoint.BASE

        try {
            // Request
            let result = await axios.post(url, company)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    updateCompany = async (id, company) => {
        let url = this.endpoint.BASE + '/' + id

        try {
            // Request
            let result = await axios.patch(url, company, { urlsToClear: [this.endpoint.BASE] })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    deleteCompany = async (id) => {
        let url = this.endpoint.BASE + '/' + id

        try {
            // Request
            let result = await axios.delete(url, { urlsToClear: [this.endpoint.BASE] })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
}