//REACT
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//MUI
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

//LIBRARY
import YellowButton from '../../UI/YellowButton';
import MainCard from '../../UI/MainCard';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES, RESPONSE_TYPES, ROLES } from '../../utils/constants'
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import AppointmentService from '../../services/AppointmentService';
import { useDispatch, useSelector, } from "react-redux";
import { showError, showSuccess } from '../../redux/snackbar';
import { hidePopup, setMessageAndButtonText } from '../../redux/popup';
import NavigationPanel from '../../UI/NavigationPanel';
import { displayPhone } from '../../utils/helper';



const ReservationApproval = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const currentUser = useSelector((state) => state.user);
    const [params, setParams] = useState({
        appointmentStatus: APPOINTMENT_STATUSES.PENDING,
        appointmentType: APPOINTMENT_TYPES.VISIT
    });

    const [appointments, setAppointments] = useState([]);
    const appointmentService = new AppointmentService();
    const [appointmentId, setAppointmentId] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const getTheme = useThemes()
    const darkMode = useSelector(state => state.darkMode)
    const [totalCount, setTotalCount] = useState(0)

    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const dispatch = useDispatch();

    const [panels, setPanels] = useState([t('adminPanel'), t('reservationOperations'), t('reservationApproval')])

    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            backgroundColor: darkMode ? 'black' : 'white',

            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '100px' : '80px',
            borderRadius: 10,
            p: 2,
        },

    }

    // service call for appointments with pagination
    const getAppointments = async (params) => {
        try {
            const response = await appointmentService.getAppointments(params);
            if (response && response.data) {
                setAppointments(response.data)
                setTotalCount(response.count)
            }
        }
        catch (e) {
            console.log(e);
        }

    }

    const getActionColumn = (object) => {
        return (
            <YellowButton
                style={{ height: '30px', width: '110px', borderRadius: 5, fontSize: 11 }}
                onClick={() => dispatch(setMessageAndButtonText({
                    'title': object.visit?.firstName + " " + object.visit?.lastName + " " + object.appointmentDate, 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                }))}>
                {t('commonShowDescription')}
            </YellowButton>
        );
    };

    {/* approve a reservation which clicked in datagrid table */ }
    const approveReservation = async (object) => {
        const body = { appointmentStatus: APPOINTMENT_STATUSES.APPROVED }

        // valid appointment update object
        if (object.id && body) {
            const result = await appointmentService.updateAppointment(object.id, body)
            if (result.status === RESPONSE_TYPES.success) {
                const list = appointments.filter(item => item.id !== object.id)
                setAppointments(list);

                dispatch(showSuccess({ message: t("successMessage") }))
                if (appointments.length <= 1)
                    setIsEmpty(!isEmpty)
            }
            else
                dispatch(showError({ message: t("errorMessageTitle") }))
        }
    };

    {/* reject a reservation which clicked in datagrid table */ }
    const declineReservation = async (object) => {

        const body = { appointmentStatus: "REJECTED" }
        setAppointmentId(object.id)

        // valid appointment update object
        if (object.id && body) {
            const result = await appointmentService.updateAppointment(object.id, body)
            if (result.status === RESPONSE_TYPES.success) {

                const list = appointments.filter(item => item.id !== object.id)
                setAppointments(list);
                dispatch(showSuccess({ message: t("successMessageForDelete") }))
                if (appointments.length <= 1)
                    setIsEmpty(!isEmpty)
            }
            else
                dispatch(showError({ message: t("errorMessageTitle") }))
        }
    };

    {/* function to allow us to do a operation for particular row, in this case approve and reject operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent="space-around">
                <Grid item>
                    <IconButton
                        onClick={() => (approveReservation(object))}>
                        <DoneIcon sx={{ color: '#469D89' }} />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("rejectFreightAppointment"),
                            'text': t("rejectFreightAppointmentText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("reject"),
                            yesButtonAction: () => {
                                declineReservation(object);
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}>
                        <CloseIcon sx={{ color: 'red' }} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };
    //örnek column oluşturma
    const columns = [
        {
            field: 'details',
            headerName: 'İşlem',
            minWidth: 180,
            renderCell: (params) => getActionColumnDetail(params.row)
        },
        {
            field: 'firstName',
            headerName: t('firstName2'),
            minWidth: 170,
            renderCell: (params) => params.row.visit?.firstName
        },
        {
            field: 'lastName',
            headerName: t('lastName'),
            minWidth: 170,
            renderCell: (params) => params.row.visit?.lastName
        },
        {
            field: 'name',
            headerName: "Şirket Adı",
            minWidth: 190,
            renderCell: (params) => params.row.company?.name
        },
        {
            field: 'phone',
            headerName: t('phoneNumberLabel'),
            width: 180,
            renderCell: (params) => displayPhone(params.row?.visit?.phoneNumber)
        },
        {
            field: 'appointmentWith',
            headerName: "Görüşülecek Kişi",
            minWidth: 290,
            renderCell: (params) => params.row.visit?.user?.firstName + " " + params.row.visit?.user?.lastName
        },
        {
            field: 'appointmentDate',
            headerName: 'Ziyaret Günü',
            minWidth: 180,
        },
        {
            field: 'appointmentTime',
            headerName: t('appointmentTime'),
            minWidth: 150,
            renderCell: (params) => params.row.appointmentTime?.slice(0, 5)
        },
        {
            field: 'appointmentStatus',
            headerName: "Randevu Durumu",
            minWidth: 190,
            renderCell: (params) => t(`${params.row.appointmentStatus}`)
        },
        {
            field: 'appointmentReason',
            headerName: "Görüşme Sebebi",
            minWidth: 190,
        },
        {
            field: 'description',
            headerName: 'Açıklama',
            minWidth: 150,
            renderCell: (params) => getActionColumn(params.row)
        },



    ];

    return (
        <MainCard isSingleCard={false}>

            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }}>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={appointments}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('reservationApproval')}
                        getFunction={getAppointments}
                        isAddButton={false}
                        totalCount={totalCount}
                        params={params}
                    />
                </Grid>
            </Grid>




        </MainCard>

    );
}

export default ReservationApproval;