//REACT
import { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"

//MUI
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

//LIBRARY
import moment from 'moment'
import 'moment/locale/tr'

// SERVICES
import AppointmentService from '../../services/AppointmentService'

// UTILS
import MainCard from '../../UI/MainCard'
import NavigationPanel from '../../UI/NavigationPanel'
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES, FREIGHT_AREAS, ROLES } from '../../utils/constants'
import { modifyPlate } from '../../utils/modifyPlate'
import { GridCloseIcon } from "@mui/x-data-grid"


const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    strongText: {
        fontWeight: 'bold',
        fontSize: '14px',
    },

    lightText: {
        fontWeight: 'normal',
        fontSize: '14px',
    },

    tableContainer: {
        margin: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    },

    headerRow: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold'
    },

    cell: {
        border: '1px solid #ccc',
        height: '80px',
        width: '300px',
        boxSizing: 'border-box',
        padding: '10px',
    },

    filledCell: {
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: '#27AE60',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#1E8449',
        },
    },
    pendingCell: {
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: 'rgba(128, 128, 128, 0.8)',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: 'rgba(128, 128, 128, 1)',
        },
    },
    inProgressCell: {
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: '#ffb266',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#ff8c00',
        },
    },
    completedCell: {
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: '#2980b9',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#1e6091',
        },
    },
    boldText: {
        fontWeight: 'bold',
        fontSize: '14px',
        boxSizing: 'border-box',
    },
    companyName: {
        fontWeight: 'bold',
        fontSize: '14px',
        textTransform: 'uppercase'
    },
    rightArrow: {
        width: 0,
        height: 0,
        borderTop: '20px solid transparent',
        borderBottom: '20px solid transparent',
        borderLeft: '20px solid rgb(2, 117, 216)',
        ml: '20px'
    },

    leftArrow: {
        width: 0,
        height: 0,
        borderTop: '20px solid transparent',
        borderBottom: '20px solid transparent',
        borderRight: '20px solid rgb(2, 117, 216)',
        mr: '20px'
    },

    modal: {
        position: 'absolute',
        width: '400px',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        overflowY: 'auto',
        maxHeight: '80vh',
        maxWidth: '80vw',
    },

    modalTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '10px',
    },

    modalContent: {
        fontSize: '1rem',
        marginBottom: '10px',
    },

    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '1.5rem',
        color: '#888',
    },


}

const FreightCalendar = (props) => {

    const { t } = useTranslation()
    const user = useSelector(state => state.user)

    // initialize services
    const appointmentService = new AppointmentService()

    const [panels,] = useState([t('adminPanel'), t('freightOperations'), t('freightCalendar')])

    const [rows, setRows] = useState([])
    const [open, setOpen] = useState(false)
    const [modalContent, setModalContent] = useState({})

    const [appointments, setAppointments] = useState([])

    const [freightArea, setFreightArea] = useState(FREIGHT_AREAS.MAIS_SEVKIYAT)
    const [slots, setSlots] = useState([])

    const [params, setParams] = useState({
        appointmentType: APPOINTMENT_TYPES.OPERATION,
        appointmentStatus: [APPOINTMENT_STATUSES.APPROVED, APPOINTMENT_STATUSES.PENDING, APPOINTMENT_STATUSES.IN_PROGRESS, APPOINTMENT_STATUSES.COMPLETED],
        // monday
        startDate: moment().day(1).format('YYYY-MM-DD'),
        // friday
        endDate: moment().day(6).format('YYYY-MM-DD'),
        freightArea,
        limit: 100,
    })

    const days = [
        { name: t('monday') },
        { name: t('tuesday') },
        { name: t('wednesday') },
        { name: t('thursday') },
        { name: t('friday') },
        { name: t('saturday') },
    ]

    // called just once to get the slots
    useEffect(() => {
        getSlots()
    }, [])

    // we use this extra useEffect to update the params when the freight area is changed since the definition of freight area is not a 
    useEffect(() => {
        setParams({
            ...params,
            freightArea,
        })
    }, [freightArea])

    useEffect(() => {
        getAppointments()
    }, [params])

    // called when the appointments are changed (after the appointments are fetched)
    useEffect(() => {
        setTableRows()
    }, [appointments])

    const getAppointments = async () => {
        const response = await appointmentService.getAppointments(params)
        if (response) {
            setAppointments(response.data)
        }
    }

    const getSlots = async () => {

        const result = await appointmentService.getSlots({
            appointmentDate: params.startDate,
        })
        if (result) {
            setSlots(result.data)
        }
    }

    const handleOpen = (appointments) => {
        setOpen(true)
        setModalContent(appointments)
    }

    // sets the rows of the table with matching appointments (appointmentDate and slotNumber)
    const setTableRows = () => {
        const rows = days.map((_, index) => {
            const row = {
                date: moment(params.startDate).add(index, 'days').format('DD-MM-YYYY'),
                slots: []
            }

            slots.forEach((_, index) => {
                const appointmentsInTheSlot = appointments.filter(appointment => (
                    appointment?.appointmentDate === row.date &&
                    appointment?.freight?.slotNumber === index + 1
                ))

                if (appointmentsInTheSlot && appointmentsInTheSlot.length > 0) {
                    const { approved, pending, inProgress, completed } = groupAppointmentsByStatus(appointmentsInTheSlot)

                    row.slots.push({
                        slotNumber: index + 1,
                        value: (
                            <Grid container item sx={{ justifyContent: 'center' }}>
                                {approved.length > 0 && (
                                    <Grid item sx={{ ...styles.filledCell, height: '25%', mb: '5px' }} onClick={() => handleOpen(approved)}>
                                        <Typography variant="body1" sx={{ ...styles.boldText, fontSize: '14px' }}>{approved.length}</Typography>
                                    </Grid>
                                )}
                                {pending.length > 0 && (
                                    <Grid item sx={{ ...styles.pendingCell, height: '25%', mb: '5px' }} onClick={() => handleOpen(pending)}>
                                        <Typography variant="body1" sx={{ ...styles.boldText, fontSize: '14px' }}>{pending.length}</Typography>
                                    </Grid>
                                )}
                                {inProgress.length > 0 && (
                                    <Grid item sx={{ ...styles.inProgressCell, height: '25%', mb: '5px' }} onClick={() => handleOpen(inProgress)}>
                                        <Typography variant="body1" sx={{ ...styles.boldText, fontSize: '14px' }} >{inProgress.length}</Typography>
                                    </Grid>
                                )}
                                {completed.length > 0 && (
                                    <Grid item sx={{ ...styles.completedCell, height: '25%', mb: '5px' }} onClick={() => handleOpen(completed)}>
                                        <Typography variant="body1" sx={{ ...styles.boldText, fontSize: '14px' }} >{completed.length}</Typography>
                                    </Grid>
                                )}

                            </Grid>
                        )
                    })
                }
            })
            return row
        })

        setRows(rows)
    }

    const groupAppointmentsByStatus = (appointments) => {

        let approved = [], pending = [], inProgress = [], completed = []

        appointments.forEach(appointment => {
            if (appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED) {
                approved.push(appointment)
            } else if (appointment.appointmentStatus === APPOINTMENT_STATUSES.PENDING) {
                pending.push(appointment)
            } else if (appointment.appointmentStatus === APPOINTMENT_STATUSES.IN_PROGRESS) {
                inProgress.push(appointment)
            } else if (appointment.appointmentStatus === APPOINTMENT_STATUSES.COMPLETED) {
                completed.push(appointment)
            }
        })

        return { approved, pending, inProgress, completed }
    }

    // called when the user clicks on the arrows to change the week
    const handleWeekChange = (direction) => {
        if (direction === 'right') {
            setParams({
                ...params,
                startDate: moment(params.startDate).add(7, 'days').format('YYYY-MM-DD'),
                endDate: moment(params.endDate).add(7, 'days').format('YYYY-MM-DD'),
            })
        } else {
            setParams({
                ...params,
                startDate: moment(params.startDate).subtract(7, 'days').format('YYYY-MM-DD'),
                endDate: moment(params.endDate).subtract(7, 'days').format('YYYY-MM-DD'),
            })
        }
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            {/* FREIGHT AREA SELECTION & WEEK SELECTION */}
            <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: '30px' }}>
                <Grid item sx={{ alignSelf: 'left', marginRight: 'auto', ml: '20px', pb: "20px" }}>
                    {/* COLOR INFORMATION */}
                    <Grid container item xs={12} sx={{ display: 'block' }}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ ...styles.pendingCell, height: '15px', width: '30px', mr: '5px' }} />
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}>{t('PENDING')}</Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ ...styles.filledCell, height: '15px', width: '30px', mr: '5px' }} />
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}>{t('APPROVED')}</Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ ...styles.inProgressCell, height: '15px', width: '30px', mr: '5px' }} />
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}>{t('IN_PROGRESS')}</Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ ...styles.completedCell, height: '15px', width: '30px', mr: '5px' }} />
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}>{t('COMPLETED')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{ pr: '20px', minWidth: '13rem' }}>
                    {/* FREIGHT AREA */}
                    <FormControl fullWidth>
                        <InputLabel id="freight-area-label">{t('freightArea')}</InputLabel>
                        <Select
                            value={freightArea}
                            label={t('freightArea')}
                            onChange={(e) => setFreightArea(e.target.value)}
                        // disabled={user.type === ROLES.OPERATION_RESPONSIBLE && freightArea}
                        >
                            {
                                Object.keys(FREIGHT_AREAS).map((area, index) => (
                                    <MenuItem key={index} value={area}>{t(FREIGHT_AREAS[area])}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sx={{ display: 'flex', pb: "3rem" }}>

                    {/* LEFT ARROW */}
                    <Grid
                        item
                        sx={{ ...styles.leftArrow, cursor: 'pointer', alignSelf: 'center' }}
                        onClick={() => handleWeekChange('left')}
                    ></Grid>

                    {/* CURRENT WEEK */}
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px', alignSelf: 'center', width: '200px', textAlign: 'center' }}>
                        {moment(params.startDate).locale('tr').format('DD MMMM')} / {moment(params.endDate).locale('tr').format('DD MMMM')}
                    </Typography>

                    {/* RIGHT ARROW */}
                    <Grid
                        item
                        sx={{ ...styles.rightArrow, cursor: 'pointer', alignSelf: 'center', mr: "20px" }}
                        onClick={() => handleWeekChange('right')}
                    ></Grid>
                </Grid>
            </Grid>

            {/* ********************************************************************** */}
            {/* CALENDAR */}
            {/* ********************************************************************** */}
            <TableContainer component={Paper} sx={styles.tableContainer}>
                <Table>
                    {/* Days of the week */}
                    <TableHead>
                        <TableRow sx={styles.headerRow}>
                            <TableCell sx={styles.boldText}></TableCell> {/* Empty cell for alignment */}
                            {/* Columns headers (dates)  */}
                            {days.map((day, index) => (
                                <TableCell key={index} align="center" sx={{ ...styles.boldText, width: '300px' }}>
                                    <Typography variant="body1" sx={styles.boldText}>{day.name}</Typography>
                                    {/* Different color for today's date */}
                                    <Typography
                                        variant="body3"
                                        sx={moment(params.startDate).add(index, 'days').format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') ? { ...styles.boldText, color: 'rgb(2, 117, 216)' } : styles.boldText}
                                    >
                                        {moment(params.startDate).add(index, 'days').format('DD-MM-YYYY')}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* Time slots and calendar slots */}
                    <TableBody>
                        {slots.map((slot, slotIndex) => (
                            <TableRow key={slotIndex}>
                                <TableCell key={slotIndex} align="center" sx={{ ...styles.boldText, width: '150px' }}>{slot.START} - {slot.END}</TableCell>
                                {rows.map((row, rowIndex) => (
                                    <TableCell key={rowIndex} align="center" sx={styles.cell}>
                                        {/* Iterate through the slots of the rows to find matching slot for each. */}
                                        {row.slots.find(slot => slot?.slotNumber === slotIndex + 1)?.value}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                    {open && (
                        <Modal open={open} onClose={() => setOpen(false)}>
                            <Grid sx={{ ...styles.modal, justifyContent: 'center', alignItems: 'center' }} container item xs={12}>

                                <Typography variant="h6" sx={styles.modalTitle}> {t('appointmentDetails') + ' (' + modalContent[0]?.appointmentDate + ')'}</Typography>
                                <Typography variant="h5" sx={{ mb: '1.5rem' }}> {slots[modalContent[0]?.freight?.slotNumber - 1]?.START + ' - ' + slots[modalContent[0]?.freight?.slotNumber - 1]?.END}</Typography>

                                <IconButton
                                    sx={styles.closeButton}
                                    onClick={() => setOpen(false)}
                                >
                                    <GridCloseIcon />
                                </IconButton>


                                {modalContent.map((appointment, index) => (
                                    < Grid container item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: '10px' }} key={index}>
                                        <Typography variant="body1" sx={{ ...styles.boldText, fontSize: '14px' }}>{appointment?.company?.name}</Typography>
                                        <Typography variant="body1" sx={{ ...styles.companyName, fontSize: '14px' }}>
                                            {modifyPlate(appointment?.freight?.plate)} - {t(`${appointment?.freight?.freightArea}`)} -  Kapı: {appointment?.freight?.gateNumber}
                                        </Typography>
                                        <Typography variant="body1" sx={{ ...styles.boldText, fontSize: '14px' }}>{t(`${appointment?.appointmentStatus}`)}  </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Modal>
                    )}
                </Table>
            </TableContainer>


        </MainCard>
    )

}


export default FreightCalendar