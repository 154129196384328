//REACT
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

//MUI
import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//LIBRARY
import MainCard from '../../UI/MainCard';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { RESPONSE_STATUSES, ROUTES } from '../../utils/constants'
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import UserService from '../../services/UserService';
import NavigationPanel from '../../UI/NavigationPanel';
import { displayPhone } from '../../utils/helper';
import { hidePopup, setMessageAndButtonText } from '../../redux/popup';

const UsersTable = (props) => {

    const userService = new UserService()

    const navigate = useNavigate();
    const getTheme = useThemes()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const darkMode = useSelector(state => state.darkMode)
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));

    const [users, setUsers] = useState([])
    const [page,] = useState(0);
    const [limit,] = useState(8);
    const [params,] = useState({ page, limit });
    const [totalCount, setTotalCount] = useState(0);
    const [panels,] = useState([t('adminPanel'), t('userSettings'), t('userList')])


    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '120px' : '85px',
            borderRadius: 10,
            p: 2,
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            backgroundColor: darkMode ? 'black' : 'white',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
    }

    const fetchUsers = async (params) => {
        const result = await userService.getUsers(params)

        if (result) {
            setUsers(result.data)
            setTotalCount(result.count)
        }
    }


    const deleteUser = async (object) => {
        const response = await userService.deleteUser(object.id)

        if (response && response.status === RESPONSE_STATUSES.success) {
            setUsers(users.filter(user => user.id !== object.id))
            setTotalCount(totalCount - 1)
        }
    }

    {/* function to allow us to do a operation for particular row in this case is a delete and edit operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                    <IconButton
                        title='Düzenle'
                        style={{
                            color: '#2980b9'
                        }}
                        onClick={() => navigate(ROUTES.USER_EDIT, { state: { request: object } })}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        title='Sil'
                        style={{
                            color: '#E45B5B'
                        }}
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("deleteTitle"),
                            'text': t("deleteText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("commonDelete"),
                            yesButtonAction: () => {
                                deleteUser(object)
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>

            </Grid>
        );
    };
    //örnek column oluşturma
    const columns = [
        {
            field: 'operation',
            headerName: t('operation'),
            minWidth: 150,
            renderCell: (params) => getActionColumnDetail(params.row)
        },
        {
            field: 'firstName',
            headerName: t('firstName'),
            minWidth: 150,
        },
        {
            field: 'lastName',
            headerName: t('lastName'),
            minWidth: 150,
        },
        {
            field: 'phone',
            headerName: t('phoneNumberLabel'),
            width: 220,
            renderCell: (params) => displayPhone(params.row?.phone)
        },
        {
            field: 'email',
            headerName: t('email'),
            minWidth: 220,
        },
        {
            field: 'type',
            headerName: t('userType'),
            minWidth: 220,
            renderCell: (params) => t(`${params.row.type}`)
        },
        {
            field: 'company',
            headerName: t('company'),
            minWidth: 170,
            renderCell: (params) => (
                <Grid container>
                    <Typography>{params.row.company?.name}</Typography>
                </Grid>
            ),

        },

    ];

    return (
        <MainCard>
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }} justifyContent='space-between'>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={users}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('userList')}
                        getFunction={fetchUsers}
                        totalCount={totalCount}
                        params={params}

                    />
                </Grid>
            </Grid>
        </MainCard>

    );
}

export default UsersTable;