//REACT
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from "react";
//MUI
import Grid from '@mui/material/Grid';
import { IconButton, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


//LIBRARY
import YellowButton from '../../UI/YellowButton'
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES, RESPONSE_TYPES, ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { setMessageAndButtonText, hidePopup, } from "../../redux/popup"
import { showSuccess, showError } from '../../redux/snackbar';

// SERVICE
import AppointmentService from '../../services/AppointmentService';
import NavigationPanel from '../../UI/NavigationPanel';
import dayjs from 'dayjs';



const ViewAppointments = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const darkMode = useSelector(state => state.darkMode)
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    // initialize services 
    const appointmentService = new AppointmentService()

    // states
    const [totalCount, setTotalCount] = useState("")

    const [params, setParams] = useState({
        appointmentType: APPOINTMENT_TYPES.VISIT,
        // appointmentStatus: Object.values(APPOINTMENT_STATUSES).filter(item => item !== APPOINTMENT_STATUSES.PENDING),
    })
    const [appointments, setAppointments] = useState([])
    const [appointmentId, setAppointmentId] = useState(0);
    const [panels, setPanels] = useState([t('adminPanel'), t('visitProcedures'), t('viewAppointments')])

    const styles = {
        topGrid: {
            p: 4,
            backgroundColor: darkMode ? 'black' : 'white',
            borderWidth: '1px',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',

        },
        bottomGrid: {
            p: 4,
            backgroundColor: darkMode ? 'black' : 'white',
            borderWidth: '1px',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '120px' : '85px',
            borderRadius: 10,
            p: 2,
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: isPhone ? DEFAULT_THEME.typography.fontWeight.bold : DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.light : DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.very_light : DEFAULT_THEME.typography.fontSize.light
        },
    }
    const getAppointments = async (params) => {
        const result = await appointmentService.getAppointments(params)
        if (result && result.data)
            setAppointments(result.data)
        setTotalCount(result.count)
    }

    const removeRow = async (object) => {
        setAppointmentId(object.id)
        const body = { appointmentStatus: "CANCELLED" }
        // valid appointment update object
        if (object.id && body) {
            const result = await appointmentService.updateAppointment(object.id, body)
            if (result.status === RESPONSE_TYPES.success) {
                const list = appointments.filter(item => item.id !== object.id)
                setAppointments(list);
                dispatch(showSuccess({ message: t("successMessageForDelete") }))

            }
            else {
                console.log(result)
                dispatch(showError({ message: t("errorMessageTitle") }))
            }
        }
    }

    {/* function to allow us to do a operation for particular row in this case is a delete and edit operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                    <IconButton
                        title='Düzenle'
                        style={{
                            color: '#2980b9'
                        }}
                        onClick={() => navigate(ROUTES.RESERVATION_EDIT, { state: { request: object } })}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        title='Sil'
                        style={{
                            color: '#E45B5B'
                        }}
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("deleteTitle"),
                            'text': t("deleteText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("commonDelete"),
                            yesButtonAction: () => {
                                removeRow(object)
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>

            </Grid>


        );
    };

    const getActionColumn = (object) => {
        return (
            <Grid item>
                <YellowButton

                    style={{
                        height: '30px', width: '110px', borderRadius: 5, fontSize: 11
                    }}
                    onClick={() => {
                        dispatch(setMessageAndButtonText({
                            'title': object.visit?.firstName + " " + object.visit?.lastName + " " + object.appointmentTime, 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                        }))
                    }}>
                    {t('commonShowDescription')}
                </YellowButton>

            </Grid>
        );
    };
    //örnek column oluşturma
    const columns = [
        {
            field: 'name',
            headerName: t('firstName2'),
            minWidth: 150,
            renderCell: (params) => params.row.visit?.firstName
        },
        {
            field: 'surname',
            headerName: t('lastName'),
            minWidth: 150,
            renderCell: (params) => params.row.visit?.lastName
        },
        {
            field: 'company',
            headerName: t('company'),
            minWidth: 170,
            renderCell: (params) => (
                <Grid container>
                    <Typography>{params.row.company?.name}</Typography>
                </Grid>
            ),
        },
        {
            field: 'appointmentWith',
            headerName: t('appointmentWith'),
            minWidth: 207,
            renderCell: (params) => params.row.visit?.user?.firstName + " " + params.row.visit?.user?.lastName
        },
        {
            field: "appointmentDate",
            headerName: t('appointmentDate'),
            minWidth: 150,
            headerAlign: "center",
            renderCell: (params) => dayjs(params.row.appointmentDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
        },
        {
            field: 'time',
            headerName: t('appointmentTime'),
            minWidth: 150,
            renderCell: (params) => params.row.appointmentTime?.slice(0, 5)
        },
        {
            field: "appointmentStatus",
            headerName: t('appointmentStatus'),
            minWidth: 220,
            headerAlign: "center",
            renderCell: (params) => t(`${params.row.appointmentStatus}`)
        },
        {
            field: 'arrivalTime',
            headerName: t('entranceTime'),
            minWidth: 150,
            renderCell: (params) => params.row.arrivalTime?.slice(0, 5)
        },
        {
            field: 'actualExitTime',
            headerName: t('exitTime'),
            minWidth: 150,
            renderCell: (params) => params.row.actualExitTime?.slice(0, 5)
        },
        {
            field: 'details',
            headerName: t('details'),
            minWidth: 150,
            renderCell: (params) => getActionColumn(params.row)
        },

    ];
    return (

        <MainCard isSingleCard={false}>

            {/* ********************************************************************** */}
            {/* NAVİGE PANEL*/}
            {/* ********************************************************************** */}
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            {/* ********************************************************************** */}
            {/* 2nd CARD DATA GRID*/}
            {/* ********************************************************************** */}
            <Grid item container sx={{ ...styles.bottomGrid }} justifyContent='space-between'>

                {/* ********************************************************************** */}
                {/* DATAGRID*/}
                {/* ********************************************************************** */}
                <Grid container item sx={{ mt: isPhone ? '1.5rem' : '' }}>
                    <CustomDataGrid
                        rows={appointments}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('viewAppointments')}
                        getFunction={getAppointments}
                        isAddButton={false}
                        totalCount={totalCount}
                        params={params}

                    />
                </Grid>
            </Grid>
        </MainCard >


    );
}

export default ViewAppointments