import { PhoneInput } from "./PhoneInput"
import { TextField } from "@mui/material"

const CustomTextField = (props) => {
    const { style, type = "text", onBlur, required, setValue, value, label, isDescriptionTF = false, isPhoneTF = false, mask, ...rest } = props
    const styles = {
        textfield: {
            [`& fieldset`]: {
                borderRadius: !isDescriptionTF ? 30 : 5,
            },
            ...style
        },
    }

    return (
        type === "tel" ?
            <PhoneInput
                value={value}
                onChange={(phone) => setValue(phone)}
                sx={{ ...styles.textfield }}
                fullWidth size="small"
                label={label}
                {...rest}>
            </PhoneInput>
            :
            <TextField sx={{ ...styles.textfield }} size="small" value={value} onChange={(e) => { setValue(e.target.value) }}
                fullWidth type={type} InputLabelProps={{ shrink: true }} label={label} autoComplete="new-password" {...rest} />
    )
}

export default CustomTextField;