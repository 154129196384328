//REACT
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

//MUI
import { Grid, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

//LIBRARY
import YellowButton from '../../UI/YellowButton';
import MainCard from '../../UI/MainCard';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { APPOINTMENT_STATUSES, ROUTES } from '../../utils/constants'
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import AppointmentService from '../../services/AppointmentService';
import { APPOINTMENT_TYPES } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { hidePopup, setMessageAndButtonText } from '../../redux/popup';
import BlackButton from '../../UI/BlackButton';
import NavigationPanel from '../../UI/NavigationPanel';
import dayjs from 'dayjs';



const ReservationTable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(30);
    const [params, setParams] = useState({
        page,
        limit,
        appointmentType: APPOINTMENT_TYPES.VISIT,
        reporting: true
    });
    const darkMode = useSelector(state => state.darkMode)
    const getTheme = useThemes()
    const [count, setCount] = useState(0)
    const [panels, setPanels] = useState([t('adminPanel'), t('reporting'), t('visitAppointments')])

    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const dispatch = useDispatch()

    // initialize services
    const appointmentService = new AppointmentService();


    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '80px' : '80px',
            borderRadius: 10,
            p: 2,
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            backgroundColor: darkMode ? 'black' : 'white',
            borderRadius: '30px',
            borderStyle: 'solid',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '10px',
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },

    }

    // service call for appointments with pagination
    const getAppointments = async (params) => {
        try {
            const response = await appointmentService.getAppointments(params);
            setAppointments(response.data);
            setCount(response.count)
        }
        catch (e) {
            console.log(e);
        }

    }

    //örnek column oluşturma
    const columns = [
        {
            field: 'name',
            headerName: t('firstName2'),
            minWidth: 150,
            renderCell: (params) => params.row.visit?.firstName
        },
        {
            field: 'surname',
            headerName: t('lastName'),
            minWidth: 150,
            renderCell: (params) => params.row.visit?.lastName
        },
        {
            field: 'company',
            headerName: t('company'),
            minWidth: 170,
            renderCell: (params) => (
                <Grid container>
                    <Typography>{params.row.company?.name}</Typography>
                </Grid>
            ),
        },
        {
            field: 'plate',
            headerName: t('plateLabel'),
            minWidth: 100,
            renderCell: (params) => params.row.visit?.plate
        },
        {
            field: 'appointmentWith',
            headerName: t('appointmentWith'),
            minWidth: 207,
            renderCell: (params) => params.row.visit?.user?.firstName + " " + params.row.visit?.user?.lastName
        },
        {
            field: "appointmentDate",
            headerName: t('appointmentDate'),
            minWidth: 150,
            headerAlign: "center",
            renderCell: (params) => dayjs(params.row.appointmentDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
        },
        {
            field: "appointmentStatus",
            headerName: t('appointmentStatus'),
            minWidth: 220,
            headerAlign: "center",
            renderCell: (params) => t(`${params.row.appointmentStatus}`)
        },
        {
            field: 'time',
            headerName: t('appointmentTime'),
            minWidth: 150,
            renderCell: (params) => params.row.appointmentTime?.slice(0, 5)
        },
        {
            field: 'arrivalTime',
            headerName: t('entranceTime'),
            minWidth: 150,
            renderCell: (params) => params.row.arrivalTime
        },
        {
            field: 'actualExitTime',
            headerName: t('exitTime'),
            minWidth: 150,
            renderCell: (params) => params.row.actualExitTime
        },

    ];

    return (
        <MainCard isSingleCard={false}>
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }}>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={appointments}
                        columns={columns}
                        showFilterBar={true}
                        getFunction={getAppointments}
                        titleOfTable={t('visitAppointments')}
                        isAddButton={false}
                        totalCount={count}
                        params={params}

                    />
                </Grid>
            </Grid>
        </MainCard>

    );
}

export default ReservationTable;