const DEFAULT_LANGUAGE = 'tr'

const APPOINTMENT_TYPES = {
    OPERATION: 'OPERATION',
    VISIT: 'VISIT',
    OTHER: 'OTHER',
}

const TRACKING_TYPES = {
    SERVICE: 'SERVICE',
    EMPLOYEE: 'EMPLOYEE',
    VEHICLE: 'VEHICLE',

}
const APPOINTMENT_STATUSES = {
    PENDING: 'PENDING', // pending approval
    APPROVED: 'APPROVED', // approved by the company admin
    IN_PROGRESS: 'IN_PROGRESS', // happening right now
    COMPLETED: 'COMPLETED', // completed by the security guard
    ABSENT: 'ABSENT', // user did not show up
    REJECTED: 'REJECTED', // rejected by the company admin
    CANCELLED: 'CANCELLED', // cancelled by the user
}

const ROLES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    AUTHORIZED_USER: 'AUTHORIZED_USER',
    PARTNER_COMPANY_AUTHORIZED_USER: 'PARTNER_COMPANY_AUTHORIZED_USER',
    OPERATION_RESPONSIBLE: 'OPERATION_RESPONSIBLE',
    SECURITY_GUARD: 'SECURITY_GUARD',
    STANDARD_USER: 'STANDARD_USER',
}
//ROUTES is actively using despite others are deprecated because of direct copy-paste from leasing web
const ROUTES = {
    INDEX: '/',
    REGISTER: '/register',
    USER_MENU: '/user-menu',
    SECURITY_MENU: '/security-menu',
    NO_PAGE: '/permission-denied',
    GUEST: '/guest-reservation',
    AUTH_USER_RESERVATION: '/auth-user-reservation',
    USER_SETTINGS: '/user-settings',
    ANALYTICS: '/analytics',
    FREIGHT_HANDLING_MENU: '/freight-handling-menu',
    RESERVATION_TABLE: '/reservation-table',
    CREATE_USER: '/create-user',
    COMPANY_CARS_TRACKING: '/company-cars-tracking',
    SERVICE_CARS_TRACKING: '/service-cars-tracking',
    CREATE_FIRM: '/create-firm',
    RESERVATION_APPROVAL: '/reservation-approval',
    RESERVATION_EDIT: '/reservation-edit',
    REPORTING_MENU: '/reporting-menu',
    CARDLESS_EMPLOYEE_TRACKING: '/cardless-employee-tracking',
    DAILY_VISIT_TRACKING: '/daily-visit-tracking',
    VISITING_OPERATIONS_MENU: '/visiting-operations-menu',
    USERS_TABLE: '/users-table',
    USER_EDIT: '/user-edit',
    VISIT_DAILY_WEEKLY_FIRM: '/visit-daily-weekly-firm',
    LOAD_RESERVATION: '/load-reservation',
    LOAD_RESERVATION_TABLE: '/load-reservation-table',
    LOAD_RESERVATION_APPROVAL: '/load-reservation-approval',
    APPOINTMENTS: '/appointments',
    SAVE_IN_OUT: '/save-in-out',
    LOAD_RESERVATION_EDIT: '/load-reservation-edit',
    CREATE_USER_INTERNAL: '/create-user-internal',
    CREATE_FIRM_INTERNAL: '/create-firm-internal',
    STANDARD_USER_MENU: '/standard-user-menu',
    STANDARD_USER_SETTINGS: '/standard-user-settings',
    SERVICE_CAR_ADD_OR_EDIT: '/service-car-add-or-edit',
    COMPANY_CAR_ADD_OR_EDIT: '/company-car-add-or-edit',
    CARDLESS_EMPLOYEE_ADD_OR_EDIT: '/cardless-employee-add-or-edit',
    UNLOAD_RESERVATION_TABLE: '/unload-reservation-table',
    CLARIFICATION_TEXT: '/clarification-text',
    CARDLESS_EMPLOYEE_EDIT: '/cardless-employee-edit',
    CONTACT_US: '/contact-us',
    CONTACT_US_PRIVATE: '/contact-us-private',
    LOAD_SAVE_IN_OUT: '/load-save-in-out',
    RESET_PASSWORD: '/reset-password',
    FREIGHT_CALENDAR: '/freight-calendar',
    VISIT_CALENDAR: '/visit-calendar',
    VIEW_APPOINTMENTS: '/view-appointments',
    USER_APPROVAL: '/user-approval',
    APPOINTMENT_BOARD: '/appointment-board',
    APPOINTMENT_BOARD_2: '/appointment-board-2',
    OPERATION_SETTINGS: '/operation-settings',
    ADD_OPERATION_RESPONSIBLE: '/add-operation-responsible',
    WORKING_HOURS_CONFIG: '/working-hours-config',
    COMPANIES: '/companies',
    EDIT_COMPANY: '/edit-company',
}
const PERMISSIONS = {

    INDEX: [ROLES.SUPER_ADMIN],
    DEFAULT: [ROLES.SUPER_ADMIN],
    USER_MENU: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.STANDARD_USER, ROLES.SECURITY_GUARD],
    SECURITY_MENU: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    LOGIN: [ROLES.SUPER_ADMIN],
    GUEST: [ROLES.SUPER_ADMIN, ROLES.GUEST],
    AUTH_USER_RESERVATION: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    USER_SETTINGS: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER],// ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    ANALYTICS: [ROLES.SUPER_ADMIN],
    FREIGHT_HANDLING_MENU: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.STANDARD_USER],
    RESERVATION_TABLE: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    CREATE_USER: [ROLES.SUPER_ADMIN],
    COMPANY_CARS_TRACKING: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE],
    SERVICE_CARS_TRACKING: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    CREATE_FIRM: [ROLES.SUPER_ADMIN],
    RESERVATION_APPROVAL: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.OPERATION_RESPONSIBLE],
    RESERVATION_EDIT: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    REPORTING_MENU: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE],
    CARDLESS_EMPLOYEE_TRACKING: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    DAILY_VISIT_TRACKING: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    VISITING_OPERATIONS_MENU: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE],
    USERS_TABLE: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER],
    USER_EDIT: [ROLES.SUPER_ADMIN, ROLES.OPERATION_RESPONSIBLE, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.AUTHORIZED_USER, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER],
    COMPANY_CAR_EDIT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    VISIT_DAILY_WEEKLY_FIRM: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    LOAD_RESERVATION: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    LOAD_RESERVATION_TABLE: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.OPERATION_RESPONSIBLE, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    LOAD_RESERVATION_APPROVAL: [ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE],
    APPOINTMENTS: [ROLES.SUPER_ADMIN],
    SAVE_IN_OUT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    LOAD_RESERVATION_EDIT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.AUTHORIZED_USER],
    CREATE_USER_INTERNAL: [ROLES.SUPER_ADMIN, ROLES.OPERATION_RESPONSIBLE, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    CREATE_FIRM_INTERNAL: [ROLES.SUPER_ADMIN, ROLES.OPERATION_RESPONSIBLE, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER],
    STANDARD_USER_MENU: [ROLES.SUPER_ADMIN, ROLES.STANDARD_USER, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE],
    STANDARD_USER_SETTINGS: [ROLES.SUPER_ADMIN, ROLES.STANDARD_USER],
    SERVICE_CAR_ADD_OR_EDIT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    COMPANY_CAR_ADD_OR_EDIT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    CARDLESS_EMPLOYEE_ADD_OR_EDIT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    UNLOAD_RESERVATION_TABLE: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    CLARIFICATION_TEXT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.GUEST],
    CARDLESS_EMPLOYEE_EDIT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    CONTACT_US: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.GUEST, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.STANDARD_USER],
    CONTACT_US_PRIVATE: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD, ROLES.GUEST, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.STANDARD_USER],
    LOAD_SAVE_IN_OUT: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    FREIGHT_CALENDAR: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE],
    VISIT_CALENDAR: [ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.SUPER_ADMIN],
    VIEW_APPOINTMENTS: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.PARTNER_COMPANY_AUTHORIZED_USER],
    USER_APPROVAL: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER],
    APPOINTMENT_BOARD: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    APPOINTMENT_BOARD_2: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD],
    OPERATION_SETTINGS: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER],
    WORKING_HOURS_CONFIG: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER],
    COMPANIES: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER],
    EDIT_COMPANY: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER],
}
const NAVBAR_LIST = [
    {
        title: 'visits',
        // items: ["vehicles", "addNewVehicle", "vehicleExpenses", "vehiclesInStock", "leasedVehicles"]
        items: [],
        permission: PERMISSIONS.VISITS
    },
    {
        title: 'companies',
        items: [],
        permission: PERMISSIONS.COMPANIES
    },
    {
        title: 'users',
        items: [],
        permission: PERMISSIONS.USERS
    },
]
const HEADERS = {
    AUTHORIZATION: 'authorization',
    LANGUAGE: 'language',
    DEVICE: 'device-id',
    DEVICE_OS: 'device-os',
    CUSTOMER_TYPE: 'customer-type'
}
const COOKIES = {
    AUTHORIZATION: 'authorization',
    LANGUAGE: 'language',
    DEVICE: 'device-id',
};

const PUBLIC_URL_LIST = [
    '/users/forgot-password',
    '/users/login',
    '/users/validate-token',
    '/users/update-password',
    '/users/reset-password',
    '/users/renew-password',
    '/users/login-with-token',
    '/users/register',
    '/appointments/guest-visit',
    '/companies/public',
    '/contactRequests',
]
const RESPONSE_STATUSES = {
    success: 'Success',
    fail: 'Fail',
    error: 'error'
}
const RESPONSE_STATUSES_CAPITAL = {
    success: 'Success',
    fail: 'Fail',
    error: 'Error'
}

const ENDPOINTS = {
    USERS: {
        BASE: '/users',
        SELF: '/users/self',
        LOGIN: '/users/login',
        LOGOUT: '/users/logout',
        REGISTER: '/users/register',
        FORGOT_PASSWORD: '/users/forgot-password',
        VALIDATE_TOKEN: '/users/validate-token',
        UPDATE_PASSWORD: '/users/update-password',
        RESET_PASSWORD: '/users/reset-password',
        RENEW_PASSWORD: '/users/renew-password',
        AUTH_TOKEN: '/users/login-with-token',

    },
    COMPANIES: {
        BASE: '/companies',
        PUBLIC: '/companies/public',
    },
    APPOINTMENTS: {
        BASE: '/appointments',
        FREIGHT: '/appointments/freight',
        VISIT: '/appointments/visit',
        OTHER: '/appointments/other',
        AVAILABLE_SLOTS: '/appointments/available-slots',
    },
    FREIGHTS: {
        BASE: '/freights',
    },
    VEHICLES: {
        BASE: '/vehicles',
    },
    TRACKING: {
        BASE: '/trackings',
    },
    CONTACT_REQUEST: {
        BASE: '/contactRequests',
    },
    FREIGHT_AREAS: {
        BASE: '/freightAreas',
    },
    CONFIGURATION: {
        BASE: '/configurations',
    },
}

const FREIGHT_AREAS = {
    1010: '1010',
    3020: '3020',
    MAIS_SEVKIYAT: 'MAIS_SEVKIYAT',
    ANTREPO: 'ANTREPO',
    GPKS: 'GPKS',
    OTHER: 'OTHER'
}

const FREIGHT_AREAS_ANG_GATES = {
    1010: [1, 2, 3, 4, 5, 10],
    3020: [1, 2],
    MAIS_SEVKIYAT: [3, 4, 5, 6, 7, 8, 9],
    ANTREPO: [1, 2, 3],
    GPKS: [1],
    OTHER: [1]
}

const RESPONSE_TYPES = {
    success: 'Success',
}

const DROP_DOWN_TYPES = {
    SELECT: 'SELECT',
    AUTO_COMPLETE: 'AUTO_COMPLETE',
    FORM_CONTROL: 'FORM_CONTROL'
}

const CLARIFICATION_TEXT = 'https://www.renault.com.tr/kisisel-verilerin-korunmasi.html';

const AREAS_FOR_3020_FREIGHTS = [
    { label: 'SEVKİYAT', value: 1 },
    { label: 'İDARİ KABUL', value: 2 }
]

const PAGINATION_LIMITS = {
    UPPER_LIMIT: 100,
    LOWER_LIMIT: 10
}

const FREIGHT_DOWNLOAD_COLUMNS = [
    // appointment fields
    'company.name',
    'appointmentDate',
    'appointmentTime',
    'appointmentStatus',

    // freight fields
    'freight.freightArea',
    'freight.plate',
    'freight.trailerNumber',

    // created by 
    'createdBy.email',

    // entrance and exit times 
    'entranceTime',
    'actualExitTime',

    'description'
]

export {
    ROUTES,
    DEFAULT_LANGUAGE,
    NAVBAR_LIST,
    HEADERS,
    COOKIES,
    PERMISSIONS,
    PUBLIC_URL_LIST,
    ROLES,
    APPOINTMENT_TYPES,
    APPOINTMENT_STATUSES,
    RESPONSE_STATUSES,
    RESPONSE_STATUSES_CAPITAL,
    ENDPOINTS,
    FREIGHT_AREAS,
    FREIGHT_AREAS_ANG_GATES,
    RESPONSE_TYPES,
    TRACKING_TYPES,
    DROP_DOWN_TYPES,
    CLARIFICATION_TEXT,
    PAGINATION_LIMITS,
    FREIGHT_DOWNLOAD_COLUMNS,
    AREAS_FOR_3020_FREIGHTS
}
