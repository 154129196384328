
//REACT
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//MUI
import { Grid } from '@mui/material';

//LIBRARY
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import CompanyService from '../../services/CompanyService';
import NavigationPanel from '../../UI/NavigationPanel';
import { RESPONSE_STATUSES, ROUTES } from '../../utils/constants';
import { formatPhone } from '../../utils/helper';

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },

}

const EditCompany = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const location = useLocation()
    const request = location.state?.request

    const companyService = new CompanyService()

    const [id,] = useState(request?.id);
    const [name, setName] = useState(request?.name);
    const [phone, setPhone] = useState(request?.phone);
    const [email, setEmail] = useState(request?.email);
    const [panels,] = useState([t('adminPanel'), t('userSettings'), t('companyList'), t('editCompany')])

    const handleSubmit = async () => {
        const response = await companyService.updateCompany(id, {
            name,
            phone: formatPhone(phone),
            email
        })

        if (response && response.status === RESPONSE_STATUSES.success) {
            navigate(ROUTES.COMPANIES)
        }
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            <Grid container xs={12} direction="column" justifyContent="space-between" mt={'2.5rem'}>

                <Grid item container justifyContent="space-between">
                    <Grid item xs={3.9}>
                        <CustomTextField
                            label={t('firstName2')} value={name} setValue={setName} />
                    </Grid>

                    <Grid item xs={3.9}>
                        <CustomTextField value={email} label={t('email')} setValue={setEmail} />
                    </Grid>

                    <Grid item xs={3.9}>
                        <CustomTextField
                            label={t('phoneNumberLabel')} value={phone} type="tel" setValue={setPhone} />
                    </Grid>

                </Grid>

            </Grid>
            {/* ********************************************************************** */}
            {/* BUTTON ROW*/}
            {/* ********************************************************************** */}
            <Grid container item sx={{ mt: '2rem' }}>
                <Grid item xs={3} sx={{ mr: '1rem' }}>
                    <YellowButton
                        type="submit"
                        onClick={() => handleSubmit()}
                    >
                        {t('commonEdit')}
                    </YellowButton>
                </Grid>
            </Grid>
        </MainCard>
    )
}

export default EditCompany;
