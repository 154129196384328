import React from 'react'
import { useTranslation } from 'react-i18next'
import YellowButton from './YellowButton'

const LanguageToggle = () => {
    const { i18n } = useTranslation()

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    return (
        <>
            <YellowButton onClick={() => changeLanguage('tr')}>TR</YellowButton>
            <YellowButton onClick={() => changeLanguage('en')}>EN</YellowButton>
        </>
    )
}

export default LanguageToggle
