// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Libraries
// Utils

// MUI
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material"
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
import InfoIcon from '@mui/icons-material/Info';
// Custom Components

// Redux

import MainCard from '../UI/MainCard';
import CustomTextField from '../UI/CustomTextField';
import YellowButton from '../UI/YellowButton';
import { useSelector } from 'react-redux';
import { CLARIFICATION_TEXT, DROP_DOWN_TYPES, PAGINATION_LIMITS, RESPONSE_STATUSES, ROLES, ROUTES } from '../utils/constants';
import { formatPhone } from '../utils/helper';

// Services
import { t } from 'i18next';
import CustomDropdown from '../UI/CustomDropdown';
import CompanyService from '../services/CompanyService';
import { hidePopup, setMessageAndButtonText } from '../redux/popup';
import UserService from '../services/UserService';

const Register = (props) => {

    const { style } = props

    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        paper: {
            //backgroundColor: 'white',
            borderWidth: '1px',
            borderRadius: '40px',
            p: 3,
            borderStyle: 'solid',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#F0F3FB',
            direction: 'column',
        },
        marginTop: {
            marginTop: '1%',
        },
        multiChild: {
            backgroundColor: 'white',
        },
        disabled: {
            backgroundColor: '#F0F3FB',
            color: '#B3B8CD',
        },
        ...style
    }

    const companyService = new CompanyService();
    const userService = new UserService()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [type, setType] = useState(ROLES.PARTNER_COMPANY_AUTHORIZED_USER)

    const [selectedCompany, setSelectedCompany] = useState()
    const [companies, setCompanies] = useState([])

    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const [companySearchParams, setCompanySearchParams] = useState({})
    const [companySearchParamsLength, setCompanySearchParamsLength] = useState(0)

    const darkMode = useSelector(state => state.darkMode)

    useEffect(() => {
        setDisabled(!checkInputs())
    }, [firstName, lastName, email, phone, checked, password, repeatPassword, selectedCompany])

    useEffect(() => {

        if (companySearchParams && companySearchParamsLength >= 2) {
            const params = {
                search: true,
                query: companySearchParams
            }
            getCompanies(params)
        }
        else if (companySearchParamsLength === 0) {
            getCompanies()
        }

    }, [companySearchParamsLength])

    const checkInputs = () => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        const phoneRegex = /^\+[0-9]{8,15}$/;

        return (
            firstName &&
            lastName &&
            emailRegex.test(email) &&
            phoneRegex.test(phone) &&
            checked &&
            password && repeatPassword &&
            selectedCompany
        )
    }

    const getCompanies = async (params) => {
        const result = await companyService.getCompaniesPublic({ ...params, limit: PAGINATION_LIMITS.UPPER_LIMIT })
        if (result && result.status === RESPONSE_STATUSES.success) {
            setCompanies(result.data)
        }
    }

    const onSubmitRequest = async () => {

        if (repeatPassword !== password) {
            dispatch(setMessageAndButtonText({
                'title': t("errorMessageTitle"), 'text': t("errorPasswordMessage"), 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
            }))
            return
        } else if (password.length <= 6) {
            dispatch(setMessageAndButtonText({
                'title': t("errorMessageTitle"), 'text': t("errorPasswordLengthMessage"), 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
            }))
            return
        }

        const registrationRequest = {
            firstName,
            lastName,
            email,
            phone: formatPhone(phone),
            companyId: selectedCompany?.id,
            password,
            type,
            kvkkPermission: checked
        }

        try {
            const result = await userService.registerUser(registrationRequest)

            if (result && result.status === RESPONSE_STATUSES.success) {
                dispatch(setMessageAndButtonText({
                    'title': t("registrationSucceeded"), 'text': t("successRegisterMessage"), "successButtonText": t('commonOkButton'), successButtonAction: () => { dispatch(hidePopup()); navigate(ROUTES.INDEX) }, isSuccessMessage: true, isErrorMessage: false
                }))
                return
            } else {
                dispatch(setMessageAndButtonText({
                    'title': t("registrationFailed"), 'text': t("failedRegisterMessage"), 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
                }))
                return
            }
        } catch (error) {
            console.log(error)

        }
    }

    const handleClick = () => {
        window.open(CLARIFICATION_TEXT)
    };
    return (
        <MainCard isSingleCard={true} sideBar={false} navBar={true} isGuest={true}>

            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <Typography sx={{ fontSize: DEFAULT_THEME.typography.fontSize.extra_large, fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold2 }}> {t('register')} </Typography>
            </Grid>

            <Grid container item sx={{ border: darkMode ? '0.5px solid white' : '0.5px solid black', color: darkMode ? 'white' : 'black', height: '1px' }} xs={4}>

            </Grid>

            <Grid container item sx={{ ...styles.subTitle }}>
                {/* Near blue color */}
                <InfoIcon sx={{ color: '#1976d2', mr: '0.5rem' }} />
                <p style={{ color: '#1976d2', fontWeight: 'bold' }}>{t('companySelectionNote')}</p>
            </Grid>

            <Grid container xs={12} sx={{ justifyContent: "center" }}>

                {/* <FormGroup sx={{ width: 'stretch' }} onChange={() => checkInputs()} > */}

                {/* ********************************************************************** */}
                {/* 1st ROW OF INPUT FIELD */}
                {/* ********************************************************************** */}
                <Grid container xs={12} direction="column" justifyContent="space-between" >

                    <Grid item container justifyContent="space-between" sx={{ mb: '2rem' }}>
                        <Grid item xs={3.9}>
                            <CustomTextField
                                placeholder="Ad" label="Ad" value={firstName} setValue={setFirstName} />
                        </Grid>

                        <Grid item xs={3.9}>
                            <CustomTextField
                                placeholder="Soyad" label="Soyad" value={lastName} setValue={setLastName} />
                        </Grid>
                        <Grid item xs={3.9}>
                            <CustomDropdown
                                type={DROP_DOWN_TYPES.AUTO_COMPLETE}
                                label={t('company')}
                                placeholder={t('company')}
                                value={selectedCompany}
                                setValue={setSelectedCompany}
                                data={companies.map((company) => { return { id: company.id, title: company.name } })}
                                searchParams={companySearchParams}
                                setSearchParams={setCompanySearchParams}
                                setSearchParamsLength={setCompanySearchParamsLength}
                                fetchFunction={getCompanies}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" sx={{ mb: '2rem' }}>
                        <Grid item xs={3.9}>
                            <CustomTextField
                                fullWidth size='small' placeholder={t('commonEmail')} label={t('commonEmail')} type="email" value={email} onChange={(e) => setEmail(e.target.value)}></CustomTextField>
                        </Grid>

                        <Grid item xs={3.9}>
                            <CustomTextField
                                fullWidth size='small' type="tel" placeholder={t('phoneNumberLabel')} label={t('phoneNumberLabel')} value={phone} setValue={setPhone}>
                            </CustomTextField>
                        </Grid>

                        <Grid item xs={3.9}>
                            <CustomTextField
                                fullWidth size='small'
                                label={t('userRole')}
                                value={t(`${type}`)}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" sx={{ mb: '2rem' }}>
                        <Grid item xs={5.9}>
                            <CustomTextField
                                type="password" fullWidth size='small' placeholder={t('password')} label={t('password')} value={password} onChange={(e) => setPassword(e.target.value)}></CustomTextField>
                        </Grid>
                        <Grid item xs={5.9}>
                            <CustomTextField
                                type="password" fullWidth size='small' placeholder={t('passwordRepeat')} label={t('passwordRepeat')} value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)}></CustomTextField>
                        </Grid>

                    </Grid>

                    <Grid item container alignItems="center" alignContent="center" justifyContent="flex-start">
                        <Grid item alignItems="center" alignContent="center" sx={{ width: '1.8rem' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onClick={(e) => setChecked(e.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item alignItems="center" alignContent="center" sx={{ mr: '2rem', mb: '0.2rem' }}>
                            <div>
                                <span onClick={() => handleClick()} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Aydınlatma Metnini</span>
                                <span> okudum ve kabul ediyorum.</span>
                            </div>

                        </Grid>
                    </Grid>

                    {/* ********************************************************************** */}
                    {/* BUTTON */}
                    {/* ********************************************************************** */}
                    <Grid item container sx={{ mt: '2rem' }}>
                        <Grid item xs={3}>

                            <YellowButton
                                disabled={disabled}
                                type="submit"
                                // style={{ color: darkMode ? 'black' : 'white' }}
                                style={disabled ? styles.disabled : {}}

                                onClick={() => onSubmitRequest()}
                            >
                                {t('register')}
                            </YellowButton>

                        </Grid>



                    </Grid>
                </Grid>

                {/* </FormGroup> */}
            </Grid>
        </MainCard >

    )
}


export default Register